import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入适配文件
import 'lib-flexible'
// 公共样式
import '../src/assets/style/common.css'
// 引入字体图标
import '../src/assets/fonts/iconfont.css'
// 引入动画样式
import animated from 'animate.css'

Vue.use(animated)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
