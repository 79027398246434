import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  // 主页
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  // 关于我们
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  // 联系我们
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  // 服务
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/Service.vue')
  },
  // 员工
  {
    path: '/member',
    name: 'Member',
    component: () => import('../views/Member.vue')
  },
  // 全系解析识别技术
  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import('../views/Analysis.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
